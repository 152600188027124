import React, { useState } from "react";
import "./OurValues.css"; // Assurez-vous d'avoir ce fichier CSS pour le style
import InnovationIcon from "../../components/assets/innovation.jpg";
import ExcellenceIcon from "../../components/assets/excellence.jpg";
import CommitmentIcon from "../../components/assets/commitment.jpg";



const OurValues = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const values = [
    {
      icon: InnovationIcon,
      title: "Innovation",
      description:
        "We believe in the importance of innovation to remain competitive and deliver cutting-edge solutions. Our team constantly seeks new ways to improve our products and services, ensuring that we stay at the forefront of industry trends."
    },
    {
      icon: ExcellenceIcon,
      title: "Excellence",
      description:
        "The quality of our services is our priority, and we strive to exceed our clients' expectations. Through meticulous attention to detail and a dedication to craftsmanship, we aim to deliver superior solutions that drive success."
    },
    {
      icon: CommitmentIcon,
      title: "Commitment",
      description:
        "We are dedicated to the success of our clients and committed to providing exceptional service at every stage of the project. Our team's passion and drive are the forces that propel us to go above and beyond in meeting our clients' needs."
    }
  ];

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? values.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === values.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="our-values-container">
      <h1 className="our-values-title">Our Values</h1>

      <section className="values-carousel">
        <div className="carousel-container">
          <button className="carousel-button prev-button" onClick={handlePrevClick}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className="carousel-wrapper">
            <div className="carousel-items" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
              {values.map((value, index) => (
                <div key={index} className="value-item">
                  <div className="value-content">
                    <div className="value-icon">
                      {/* Ajoutez un style en ligne ou une classe CSS pour contrôler la taille */}
                      <img src={value.icon} alt={value.title} className="icon-image" />
                    </div>
                    <h2>{value.title}</h2>
                    <p>{value.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="carousel-button next-button" onClick={handleNextClick}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </section>

      <div className="cta-section">
        <p>
          Want to learn more about how we can work together?{" "}
          <a href="/contactus" className="cta-link">
            Contact us today!
          </a>
        </p>
      </div>
    </div>
  );
};

export default OurValues;
