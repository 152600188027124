import React from "react";
import "./ServicesAdvanced.css"; // Assurez-vous que le chemin est correct
import avmsImage from "../../components/assets/avms.jpg"; // Assurez-vous que ce chemin est correct

function ServicesAvms() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Automated Visitor Management System (AVMS)</h1>
      <p className="service-intro">
        The Automated Visitor Management System (AVMS) is a software and hardware solution designed to automate and expedite the process of registering visitors at reception lobbies of institutions. Instead of traditional visitor registration books, our system collects visitor data in two efficient ways:
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={avmsImage} // Assurez-vous que ce chemin est correct
            alt="Automated Visitor Management System"
            className="service-image"
          />
          <div className="service-details">
            <p className="features">Features include:</p>
            <ul className="features-list">
              <li>Pre-booked Appointments: Hosts can pre-book appointments and enter visitor details. Upon arrival, visitors simply enter a code received beforehand, and their details are automatically loaded and saved. Hosts receive text or email notifications upon guest arrival.</li>
              <li>Interactive Tablet Registration: Visitors can accurately enter their data at an interactive tablet in the reception lobby. This ensures accurate data collection, privacy, and cloud storage to prevent data loss. The system offers superior reporting, data privacy features, and comprehensive data collection, including optional visitor snapshots.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesAvms;
