import React, { useState } from 'react';
import './OurExperience.css'; // Assurez-vous d'ajouter les styles appropriés
import certificates from '../../components/assets/certificates.png';
import teamsexperience from '../../components/assets/teamsexp.png';

const clients = [
  { name: '', logo: teamsexperience },

];

const caseStudies = [
  { title: 'Case Study 1', description: 'Description of Case Study 1', status: 'Completed' },
  { title: 'Case Study 2', description: 'Description of Case Study 2', status: 'In Progress' },
];

const certifs = [
  { name: 'Certificate', logo: certificates },
];

const OurExperience = () => {
  const [selectedTab, setSelectedTab] = useState('clients'); // État pour gérer l'onglet sélectionné

  return (
    <div className="experience-container">
      <div className="tab-banner">
        <h1>Our Experience</h1>
      </div>

      <div className="tab-buttons">
        <button
          className={selectedTab === 'clients' ? 'active' : ''}
          onClick={() => setSelectedTab('clients')}
        >
          Our teams
        </button>
        {/* <button
          className={selectedTab === 'case-studies' ? 'active' : ''}
          onClick={() => setSelectedTab('case-studies')}
        >
          Case Studies
        </button> */}
        <button
          className={selectedTab === 'certificates' ? 'active' : ''}
          onClick={() => setSelectedTab('certificates')}
        >
          Certificates
        </button>
      </div>

      {selectedTab === 'clients' && (
        <section className="clients-section">
          {/* <h2>Our Clients</h2> */}
          <div className="clients-grid">
            {clients.map((client) => (
              <div key={client.name} className="client-card">
                <img src={client.logo} alt={client.name} className="client-logo" />
                <p>{client.name}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* {selectedTab === 'case-studies' && (
        <section className="case-studies-section">
          <h2>Case Studies</h2>
          <div className="case-studies-list">
            {caseStudies.map((study) => (
              <div key={study.title} className="case-study-card">
                <h3>{study.title}</h3>
                <p>{study.description}</p>
                <p>Status: {study.status}</p>
              </div>
            ))}
          </div>
        </section>
      )} */}

{selectedTab === 'certificates' && (
  <section className="certificates-section">
    <div className="certificates-content">
      <div className="certificates-text">
        <ul className="certificates-list">
          <li>SANS GIAC Global Industrial Cyber Security Professional GICSP.</li>
          <li>ISO-27001 Lead Auditor.</li>
          <li>Risk and Information Systems Control CRISC.</li>
          <li>ISACA Certified Information Security Manager CISM.</li>
          <li>Information Systems Auditor CISA.</li>
          <li>Managing Successful Programs Certified Practitioner MSP.</li>
          <li>PMI Project Management Professional - PMP.</li>
          <li>Information Systems Security Professional CISSP.</li>
          <li>Offensive Security Certified Professional OSCP.</li>
          <li>ITIL certified.</li>
        </ul>
      </div>
      <div className="certificates-image">
        <img src={certificates} alt="Certificates" className="certificate-logo" />
      </div>
    </div>
  </section>
)}

    </div>
  );
};

export default OurExperience;
