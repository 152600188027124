import React from 'react';
import './ServicesOverview.css'; // Assurez-vous que ce fichier existe et contient les styles ci-dessous
import { Link } from 'react-router-dom';
import ebmsImage from '../../components/assets/ebms_overview.png';
import legalImage from '../../components/assets/legal.jpg';
import healthImage from '../../components/assets/health.jpg';
import signImage  from '../../components/assets/sign.jpg';
import contractImage  from '../../components/assets/contract.jpg';
import crmImage  from '../../components/assets/crm_over.jpg';
import helpdeskImage  from '../../components/assets/helpdesk.jpg';
import edmsImage  from '../../components/assets/edms.jpg';


const servicesData = [
  {
    title: 'Electronic Board Management System',
    description: 'Facilitates secure communication for directors and executives. Enhances transparency and accountability in meetings. Saves costs through paperless operations.',
    imageUrl: ebmsImage,
    showDetails: true, // Mettre à true pour afficher le bouton
    detailsLink: '/services/ebms'
  },
  {
    title: 'Legal Management System',
    description: 'Manages legal processes, cases, and documents. Streamlines legal operations and enhances compliance.',
    imageUrl: legalImage,
  },
  {
    title: 'Environmental Health and Safety Management',
    description: 'Manages workplace safety, health, and environmental compliance. Tracks incidents, audits, and regulatory requirements.',
    imageUrl: healthImage,
  },
  {
    title: 'E-Sign',
    description: 'Enables electronic signatures for documents. Enhances document workflow efficiency and security.',
    imageUrl: signImage
  },
  {
    title: 'Contract Lifecycle Management',
    description: 'Manages contracts from creation to renewal or expiration. Improves contract visibility, compliance, and efficiency.',
    imageUrl: contractImage
  },
  {
    title: 'Customer Relations Management System (CRM)',
    description: 'Manages customer interactions and relationships. Tracks leads, deals, and customer communications.',
    imageUrl: crmImage
  },
  {
    title: 'Helpdesk & Complaint Management System',
    description: 'Manages customer support tickets and complaints. Enhances resolution times and customer satisfaction.',
    imageUrl: helpdeskImage
  },
  // {
  //   title: 'Exam Management Software',
  //   description: 'Manages exam scheduling, administration, and grading. Supports online exam delivery and student performance tracking.',
  //   imageUrl: 'path/to/image8.jpg'
  // },
  // {
  //   title: 'Change Management Solution',
  //   description: 'Manages organizational changes and initiatives. Ensures smooth transitions and stakeholder engagement.',
  //   imageUrl: 'path/to/image9.jpg'
  // },
  // {
  //   title: 'Knowledge Management Solution',
  //   description: 'Captures, stores, and shares organizational knowledge. Improves collaboration and decision-making.',
  //   imageUrl: 'path/to/image10.jpg'
  // },
  // {
  //   title: 'Audit Management System',
  //   description: 'Manages audit planning, execution, and reporting. Tracks audit findings and compliance actions.',
  //   imageUrl: 'path/to/image11.jpg'
  // },
  {
    title: 'Document Management System (EDMS)',
    description: 'Manages documents throughout their lifecycle. Provides secure access and version control.',
    imageUrl: edmsImage
  },
  // {
  //   title: 'Social Media Monitoring Solution',
  //   description: 'Monitors and analyzes social media channels. Tracks brand mentions, trends, and customer sentiment.',
  //   imageUrl: 'path/to/image13.jpg',
  //   showDetails: true, // Mettre à true pour afficher le bouton
  //   detailsLink: '/services/social-media-monitoring',
  //   isLast: true // Indique que c'est la dernière carte
  // }
];

const ServicesOverview = () => {
  return (
    <div className="services-overview-grid">
      {servicesData.map((service, index) => (
        <div key={index} className={`services-overview-container ${service.isLast ? 'last-card' : ''}`}>
          <div className="services-overview-card">
            <img src={service.imageUrl} alt={service.title} className="services-overview-image" />
          </div>
          <div className="services-overview-text">
            <h3 className="services-overview-title">{service.title}</h3>
            <p className="services-overview-description">{service.description}</p>
            {service.showDetails && service.detailsLink && (
              <Link to={service.detailsLink} className={`services-overview-button ${ 'green'}`}>
                More Details
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesOverview;
