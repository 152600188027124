import React from "react";
import "./ServicesAdvanced.css"; // Assurez-vous que le chemin est correct
import mamsImage from "../../components/assets/mams.jpg"; // Assurez-vous que ce chemin est correct

function ServicesMams() {
  return (
    <div className="service-page">
      {/* Titre et introduction en dehors de la section */}
      <h1 className="service-title">Mobile Assets Management System (MAMS)</h1>
      <p className="service-intro">
        MAMS enables organizations to manage mobile assets, such as vehicles, equipment, and tools, efficiently. It provides real-time tracking, maintenance scheduling, and operational analytics for mobile assets. MAMS enhances asset utilization, reduces operational costs, and improves fleet management efficiency.
      </p>
      
      {/* Section avec l'image à gauche et le texte à droite */}
      <section className="service-section">
        <div className="service-content-grid">
          <img
            src={mamsImage} // Assurez-vous que ce chemin est correct
            alt="Mobile Assets Management System"
            className="service-image"
          />
          <div className="service-details">
            <p className="features">Features include:</p>
            <ul className="features-list">
              <li>Real-time tracking of mobile assets, including vehicles, equipment, and tools.</li>
              <li>Maintenance scheduling to ensure timely upkeep and reduce downtime.</li>
              <li>Operational analytics to optimize asset utilization and manage costs effectively.</li>
              <li>Enhanced fleet management capabilities to streamline operations and improve efficiency.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesMams;
