import React, { useState } from 'react';
import './Contact.css';
// import axios from 'axios';
import emailjs from '@emailjs/browser'; // Importation de la bibliothèque EmailJS

const Contactus = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // État pour le message de confirmation

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);

  const sendmail = (e) => {

    // Configuration de l'envoi de l'email avec EmailJS
    emailjs.send(
      'service_bpfe5k7', // Remplacez par votre Service ID
      'template_63fmi0n', // Remplacez par votre Template ID
      {
        email: email,
        to_name: "Belgacem", // Votre adresse email de destination
        subject: subject,
        message: message,
      },
      'wE4DhYnm1BdnfKt0K' // Remplacez par votre User ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSuccessMessage('We have received your message and will get back to you soon!'); // Mettre à jour le message de confirmation
      setEmail('');
      setSubject('');
      setMessage('');
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setError('Error sending email. Please try again.');
    });
  };


  // const sendmail = () => {
  //   axios.defaults.withCredentials = true;
  //   axios.get('https://www.2wglobalservices.com/build/contact.php', { // https://www.2wglobalservices.com/contact.php
  //     email: email,
  //     subject: subject,
  //     message: message
  //   })
  //   .then((res) => {
  //     // alert(res.data); // Afficher la réponse du serveur
  //     if (res.data.message === "Email sent successfully") {
  //       console.log(res.data);
  //       setSuccessMessage('Email sent successfully'); // Mettre à jour le message de confirmation
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setError('Error sending email. Please try again.');
  //     setSuccessMessage(''); // Réinitialiser le message de confirmation
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !subject || !message) {
      setError('All fields are required');
      setSuccessMessage(''); // Réinitialiser le message de confirmation en cas d'erreur
    } else {
      setError('');
      setSuccessMessage(''); // Réinitialiser le message de confirmation avant d'envoyer le mail
      sendmail();
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label>Professional Email :</label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Subject :</label>
            <input
              type="text"
              value={subject}
              onChange={handleSubjectChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Request :</label>
            <textarea
              value={message}
              onChange={handleMessageChange}
              required
            ></textarea>
          </div>
          <button type="submit">Send</button>
          {successMessage && <p className="success-message">{successMessage}   </p>} {/* Afficher le message de confirmation */}
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
      <div className="info-container">
        <h2>Informations</h2>
        <ul>
          <li><strong>Email :</strong> contact@aroundexpressions.com</li>
          <li><strong>Localisation :</strong> Parish of Santo António Lisbon 11 1070-100</li>
          <li><strong>Phone Number :</strong> +351 912 549 922</li>
          {/* <li><strong>WhatsApp :</strong> +XX XXX XXX XXX</li> */}
        </ul>
      </div>
    </div>
  );
};

export default Contactus;
